import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import PortfolioPage from "./portfolio";
import AboutPage from "./about";
import ContactPage from "./contact";
import ServicesPage from "./services";
import LegalPage from "./legal";
import { Switch, Route, Router } from "./../util/router";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import Chat from "./../components/Chat";
import { ThemeProvider } from "./../util/theme";

function App(props) {
  return (
    <ThemeProvider>
      <>
        <Chat />
        <Router>
          <>
            <Navbar
              color="default"
              logo="/images/tminal-logo-black.png"
              logoInverted="/images/tminal-logo-black.png"
            />

            <Switch>
              <Route exact path="/" component={IndexPage} />
              
              <Route exact path="/about" component={AboutPage} />

              <Route exact path="/portfolio" component={PortfolioPage} />

              <Route exact path="/services" component={ServicesPage} />
              
              <Route exact path="/contact" component={ContactPage} />

              <Route exact path="/legal/:section" component={LegalPage} />

              <Route component={NotFoundPage} />
            </Switch>

            <Footer
              bgColor="default"
              size="medium"
              bgImage=""
              bgImageOpacity={1}
              description="A software house business"
              copyright={`© ${new Date().getFullYear()} Tminal Ltd.`}
              logo="/images/tminal-logo-black.png"
              logoInverted="/images/tminal-logo-black.png"
              sticky={true}
            />
          </>
        </Router>
      </>
    </ThemeProvider>
  );
}

export default App;
